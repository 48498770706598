module.exports = {
  USER: 'api@acolin.com',
  PASS: 'apps4Acol!n',
  SECRET_KEY: '6LeoJOcUAAAAAHGyhLQGlKMldUr4fc4EeiWnFkBl',
  EMAILS_TO: 'it@acolin.com',
  EMAILS_CC: '',
  // EMAILS_BCC: 'borko.jovanovic@acolin.com, ivan.dondur@acolin.com, bogdan.petrovic@acolin.com, dusan.lazovic@acolin.com',
  EMAILS_BCC: 'dusan.lazovic@acolin.com',
  URL: 'https://finsa.acolin.com/api'
};
